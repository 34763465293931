<template>
  <userTemplate>
    <div class="bg-danger font-28 pd-x-5">
      <el-row>
        <el-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 16, offset: 4 }"
          :lg="{ span: 8, offset: 8 }"
        >
          <div>
            <p class="mg-y-6 text-white text-center">{{ user.name }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row class="pd-x-5 bg-primary text-white text-center bg-primary">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 16, offset: 4 }"
        :lg="{ span: 8, offset: 8 }"
        class="h-100 min-height-screen-235 is-flex ai-center js-center"
      >
        <div>
          <div>
            <i style="font-size: 72px" class="fas fa-trophy"></i>
          </div>
          <div>
            <h2 v-if="userList != null">คุณได้ลำดับที่ {{ rateNow }}</h2>
          </div>
          <div>
            <h3>
              คะแนนรวม
              <span v-if="userList != null">{{ userList.point | comma }}</span>
              <span v-else>0 คะแนน</span>
            </h3>
          </div>
        </div>
      </el-col>
    </el-row>
  </userTemplate>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import userTemplate from "@/template/VoteUserTemplate";
export default {
  components: {
    userTemplate
  },
  beforeDestroy() {
    clearInterval(this.intervalation);
  },
  async mounted() {
    this.getRang();
    if (this.step.state != "rank" && this.step.qId < 16) {
      await this.getStateNow();
    }
    this.intervalation = setInterval(() => {
      this.getState();
    }, 1000);
  },
  data() {
    return {
      correct: false,
      userList: null,
      rateNow: null
    };
  },
  watch: {
    step() {
      if (this.step.state != "rank") {
        this.checkRouterUser(this.step.state);
      }
    }
  },
  methods: {
    getRang() {
      let obj = {
        roomId: this.room.roomId,
        qId: this.step.qId != "undefined" ? this.step.qId : this.step.qid
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("rating", obj).then(res => {
        // console.log("rating", res, this.userList);
        if (res.data.success) {
          let user = res.data.obj.sort((a, b) => {
            let modifier = -1;
            if (a.point < b.point) return -1 * modifier;
            if (a.point > b.point) return 1 * modifier;
            return 0;
          });

          let rate = user.findIndex(a => a.id == this.user.id);
          let userFilter = user.filter(a => a.id == this.user.id);
          // console.log("rate", rate, "userFilter", userFilter);
          if (userFilter.length > 0) {
            this.userList = userFilter[0];
            this.rateNow = rate > -1 ? rate + 1 : 0;
            if (this.userList.isPoint != null) {
              this.userList.isPoint == 0
                ? (this.correct = false)
                : (this.correct = true);
            } else {
              this.userList.isPoin = 0;
            }
          }
        } else {
        }
      });
    }
  }
};
</script>